import React, { useState, useEffect } from "react";
import axios from "axios";
import QuestionWiseAnalysis from "./QuestionWiseAnalysis";
import CommonAnalysisTable from "./CommonAnalysisTable";
import PageNavigation from "components/CustomComponents/Navbars/PageNavigation";
import { useParams } from "react-router-dom";
import TableLoader from "./TableLoader";
import { serverDjangoURL } from "variables/ServerURL";
import { useSelector } from "react-redux";
function TestReport() {
  const { testId } = useParams();

  const [bloomData, setBloomData] = useState([]);
  const [bloomDataLoading, setBloomDataLoading] = useState(true);
  const [bloomDataError, setBloomDataError] = useState(null);

  const [levelOfEffort, setLevelOfEffort] = useState([]);
  const [levelOfEffortLoading, setLevelOfEffortLoading] = useState(true);
  const [levelOfEffortError, setLevelOfEffortError] = useState(null);

  const [dokData, setdokData] = useState([]);
  const [dokDataLoading, setdokDataLoading] = useState(true);
  const [dokDataError, setdokDataError] = useState(null);
  const userInfo = useSelector((state) => state.user.userinfo);

  useEffect(() => {
    fetchTBloomData(testId);
    fetchEffortLevelData(testId);
    fetchDokLevelData(testId);
  }, [testId]);

  const fetchTBloomData = async (testId) => {
    setBloomDataLoading(true);
    try {
      const response = await axios.post(
        `${serverDjangoURL}/api/search/getTesBloomLevelAnalysis/`,
        {
          userId: userInfo.userId,
          testId: testId,
        }
      );
      if (response?.data?.data) {
        setBloomData(bloomModifiedData(response.data.data));
      }
      // console.log("response", response.data.data);
      // const apiData = response.data.payload;

      // setBloomData(bloomModifiedData(apiData));
      // setChartData(generateChartData(apiData));
    } catch (error) {
      setBloomDataError("Failed to fetch Bloom data");
    } finally {
      setBloomDataLoading(false);
    }
  };

  const fetchEffortLevelData = async (testId) => {
    setLevelOfEffortLoading(true);
    try {
      const response = await axios.post(
        `${serverDjangoURL}/api/search/getTesDifficultyAnalysis/`,
        {
          userId: userInfo.userId,
          testId: testId,
        }
      );
      if (response?.data?.data) {
        setLevelOfEffort(levelOdEffortModifiedData(response.data.data));
      }
    } catch (error) {
      setLevelOfEffortError("Failed to fetch Level Of Effort data");
    } finally {
      setLevelOfEffortLoading(false);
    }
  };

  const fetchDokLevelData = async (testId) => {
    setdokDataLoading(true);
    try {
      const response = await axios.post(
        `${serverDjangoURL}/api/search/getTestDOKLevelAnalysis/`,
        {
          userId: userInfo.userId,
          testId: testId,
        }
      );

      if (response?.data?.data) {
        setdokData(dokModifiedData(response.data.data));
      }
    } catch (error) {
      setdokDataError("Failed to fetch Depth of Knowledge (DOK) wise Analysis");
    } finally {
      setdokDataLoading(false);
    }
  };

  const bloomModifiedData = (apiData) => {
    // Validate input
    if (!apiData || !Array.isArray(apiData)) {
      throw new Error("Invalid input: apiData must be an array of objects");
    }

    try {
      const bloomResult = apiData
        .map((data) => {
          if (!data || typeof data !== "object") {
            console.error("Invalid entry in apiData:", data);
            return null;
          }

          return {
            testName: data.level || "Unknown",
            recall: roundToTwo(data.recall_level?.avg_marks?.value ?? 0),
            understand: roundToTwo(
              data.understand_level?.avg_marks?.value ?? 0
            ),
            analysis: roundToTwo(data.analysis_level?.avg_marks?.value ?? 0),
            create: roundToTwo(data.create_level?.avg_marks?.value ?? 0),
            application: roundToTwo(
              data.application_level?.avg_marks?.value ?? 0
            ),
            evaluate: roundToTwo(data.evaluate_level?.avg_marks?.value ?? 0),
          };
        })
        .filter((item) => item !== null);

      console.log("bloomResult", bloomResult);

      return [
        {
          map: {
            testName: "Bloom Name",
            recall: "Recall",
            understand: "Understand",
            analysis: "Analysis",
            create: "Create",
            application: "Application",
            evaluate: "Evaluate",
          },
          questions: bloomResult,
        },
      ];
    } catch (error) {
      console.error("Error processing bloomModifiedData:", error);
      throw new Error("An error occurred while processing the bloom data");
    }
  };

  // Helper function to round numbers to two decimal places
  const roundToTwo = (num) => {
    return Math.round(num * 100) / 100;
  };

  const levelOdEffortModifiedData = (apiData) => {
    // Validate input
    if (!apiData || !Array.isArray(apiData)) {
      throw new Error("Invalid input: apiData must be an array of objects");
    }

    try {
      const dokResult = apiData
        .map((data) => {
          if (!data || typeof data !== "object") {
            console.error("Invalid entry in apiData:", data);
            return null;
          }

          return {
            testName: data.level || "Unknown",
            low: roundToTwo(data.low_difficulty?.avg_marks?.value ?? 0),
            moderate: roundToTwo(data.medium_difficulty?.avg_marks?.value ?? 0),
            high: roundToTwo(data.tough_difficulty?.avg_marks?.value ?? 0),
          };
        })
        .filter((item) => item !== null);

      return [
        {
          map: {
            testName: "Effort Level",
            low: "Low",
            moderate: "Moderate",
            high: "High",
          },
          questions: dokResult,
        },
      ];
    } catch (error) {
      console.error("Error processing bloomModifiedData:", error);
      throw new Error("An error occurred while processing the bloom data");
    }
  };

  const dokModifiedData = (apiData) => {
    // Validate input
    if (!apiData || !Array.isArray(apiData)) {
      throw new Error("Invalid input: apiData must be an array of objects");
    }

    try {
      const dokResult = apiData
        .map((data) => {
          if (!data || typeof data !== "object") {
            console.error("Invalid entry in apiData:", data);
            return null;
          }

          return {
            testName: data.level || "Unknown",
            Application: roundToTwo(
              data.application_level?.avg_marks?.value ?? 0
            ),
            Extended_Thinking: roundToTwo(
              data.extended_thinking_level?.avg_marks?.value ?? 0
            ),
            Recall: roundToTwo(data.recall?.avg_marks?.value ?? 0),
            Strategic_Thinking: roundToTwo(
              data.strategic_thinking?.avg_marks?.value ?? 0
            ),
          };
        })
        .filter((item) => item !== null);

      return [
        {
          map: {
            testName: "Difficult Level",
            Application: "Application",
            Extended_Thinking: "Extended Thinking",
            Recall: "Recall",
            Strategic_Thinking: "Strategic Thinking",
          },
          questions: dokResult,
        },
      ];
    } catch (error) {
      console.error("Error processing bloomModifiedData:", error);
      throw new Error("An error occurred while processing the bloom data");
    }
  };

  return (
    <>
      <PageNavigation logoText={"Report"} back={"/assessment-report"} />
      <div style={{ marginTop: "45px", marginBottom: "45px" }}>
        {bloomDataLoading ? (
          <TableLoader
            heading={"Bloom Level wise Analysis"}
            data={"Loading Bloom Level wise Analysis data..."}
            loading={bloomDataLoading}
            error={bloomDataError}
          />
        ) : bloomDataError ? (
          <TableLoader
            heading={"Bloom Level wise Analysis"}
            data={"Failed to fetch Time Managemenrt  data"}
            loading={bloomDataLoading}
            error={bloomDataError}
          />
        ) : (
          <CommonAnalysisTable
            data={bloomData}
            heading={"Bloom Level wise Analysis"}
          />
        )}

        <div style={{ display: "flex", gap: "50px" }}>
          {dokDataLoading ? (
            <TableLoader
              heading={"Depth of Knowledge (DOK) wise Analysis"}
              data={"Loading Depth of Knowledge (DOK) wise Analysis data..."}
              loading={dokDataLoading}
              error={dokDataError}
            />
          ) : dokDataError ? (
            <TableLoader
              heading={"Depth of Knowledge (DOK) wise Analysis"}
              data={
                "Failed to fetch Depth of Knowledge (DOK) wise Analysis  data"
              }
              loading={dokDataLoading}
              error={dokDataError}
            />
          ) : (
            <CommonAnalysisTable
              data={dokData}
              heading={"Depth of Knowledge (DOK) wise Analysis"}
            />
          )}

          {levelOfEffortLoading ? (
            <TableLoader
              heading={"Effort Level wise Analysis"}
              data={"Loading Effort Level wise Analysis data..."}
              loading={levelOfEffortLoading}
              error={levelOfEffortError}
            />
          ) : levelOfEffortError ? (
            <TableLoader
              heading={"Effort Level wise Analysis"}
              data={"Failed to fetch Time Managemenrt  data"}
              loading={levelOfEffortLoading}
              error={levelOfEffortError}
            />
          ) : (
            <CommonAnalysisTable
              data={levelOfEffort}
              heading={"Effort Level wise Analysis"}
            />
          )}
        </div>

        <QuestionWiseAnalysis testId={testId} userId={userInfo.userId} />
      </div>
    </>
  );
}

export default TestReport;
