import React, { useState, useEffect } from "react";
import axios from "axios";
import { TablePagination } from "@material-ui/core";
import { useSelector } from "react-redux";
import AssignMentChart from "./AssignMentChart";
import { serverDjangoURL } from "variables/ServerURL";
function AssignMentOverallReport() {
  const [overallPerformanceData, setOverallPerformanceData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [overallPerformanceLoading, setOverallPerformanceLoading] =
    useState(true);
  const [overallPerformanceError, setOverallPerformanceError] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const userInfo = useSelector((state) => state.user.userinfo);

  useEffect(() => {
    fetchOverallPerformanceData();
  }, []);

  useEffect(() => {
    updateChartData(); // Update chart data whenever pagination changes
  }, [page, rowsPerPage, overallPerformanceData]);

  const fetchOverallPerformanceData = async () => {
    setOverallPerformanceLoading(true);
    try {
      const response = await axios.post(
        `${serverDjangoURL}/api/search/getOverallPerformanceAnalysisReport/`,
        { userId: userInfo.userId }
      );

      if (response?.data?.data) {
        setOverallPerformanceData(
          processOverallPerformanceData(response.data.data)
        );
      }
    } catch (error) {
      setOverallPerformanceError("Failed to fetch overall performance data");
    } finally {
      setOverallPerformanceLoading(false);
    }
  };

  const processOverallPerformanceData = (apiData) => {
    return apiData.map((q) => ({
      test_id: q?.test_id,
      your_marks: q.user_marks ? q.user_marks : "NA",
      test_name: q?.test_name,
      averageMarks: q.average_marks,
      topperMarks: q.topper_marks,
      top10Average: q.top_10_average,
      total_marks: q.total_marks,
    }));
  };

  const updateChartData = () => {
    const startIndex = page * rowsPerPage;
    const currentData = overallPerformanceData.slice(
      startIndex,
      startIndex + rowsPerPage
    );

    setChartData({
      series: [
        {
          name: "Your mark",
          data: currentData.map((item) => item.your_marks),
        },
        {
          name: "Avg of all students",
          data: currentData.map((item) => item.averageMarks),
        },
        {
          name: "Topper Marks",
          data: currentData.map((item) => item.topperMarks),
        },
        {
          name: "Avg of top 10 percentile",
          data: currentData.map((item) => item.top10Average),
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        xaxis: {
          categories: currentData.map((item) => item.test_name),
        },
        yaxis: {
          title: {
            text: "Marks",
          },
        },
      },
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const paginatedData = overallPerformanceData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div className="report-table-container">
      <div
        style={{
          boxShadow: "0px 2px 22px 0px rgba(0, 0, 0, 0.12)",
          padding: "10px 0 10px 10px",
          marginBottom: "20px",
          backgroundColor: "#fff",
        }}
      >
        <h2 style={{ borderBottom: "1px solid #E9E9E9" }}>
          Overall Performance Analysis
        </h2>
        <div
          style={{
            display: "flex",
          }}
        >
          {overallPerformanceLoading ? (
            <div>Loading...</div>
          ) : overallPerformanceError ? (
            <p>{overallPerformanceError}</p>
          ) : (
            <>
              {/* Chart Component */}
              <AssignMentChart
                chartData={chartData}
                style={{ width: "100%", backgroundColor: "#fff" }}
              />

              {/* Table Component */}
              <div style={{ width: "100%", backgroundColor: "#fff" }}>
                <table>
                  <thead>
                    <tr>
                      <th>Test Name</th>
                      <th>Your Marks</th>
                      <th>Avg of all students</th>
                      <th>Topper Marks</th>
                      <th>Avg of top 10 percentile</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((item, index) => (
                      <tr key={index}>
                        {/* <td>{item.testName}</td> */}
                        <td
                          style={{
                            width: "20%",
                            textAlign: "left",
                            whiteSpace: "normal",
                          }}
                        >
                          {item.test_id ? (
                            <a
                              href={`/report/${item.test_id}`}
                              style={{ color: "#32b8b0" }}
                            >
                              {item.test_name}{" "}
                            </a>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>{item.your_marks}</td>
                        <td>{item.averageMarks}</td>
                        <td>{item.topperMarks}</td>
                        <td>
                          {item.top10Average} / {item.total_marks}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <TablePagination
                  component="div"
                  count={overallPerformanceData.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              </div>

              {/* Pagination */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AssignMentOverallReport;
