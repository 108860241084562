import React from "react";
import ReactApexChart from "react-apexcharts";
function AssignMentChart({ chartData, style }) {
  if (Object.keys(chartData).length === 0) {
    return null;
  }

  return (
    <div style={style}>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default AssignMentChart;
