import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import JSZip from "jszip";
// import { saveAs } from "file-saver";
import fileUpload from "../../../assets/img/upload-file_1.png";
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
import { serverDjangoURL } from "../../../variables/ServerURL";
const styles = {
  container: {
    boxShadow: "0px 2px 10px 0px  rgba(0,0,0,0.15)",
    borderRadius: "5px",
    padding: "30px",
    //   width: '400px',
    textAlign: "center",
    cursor: "pointer",
  },
  uploadText: {
    fontSize: "24px",
    fontWeight: "500",
    color: "#425466",
  },
  files: {
    textAlign: "start",
  },
  removeButton: {
    background: "none",
    color: "inherit",
    border: "none",
    padding: 0,
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
    paddingLeft: "10px",
    fontSize: "18px",
    fontWeight: "700",
  },
  uploadButton: {
    border: "none",
    background: "linear-gradient(264.15deg, #4F90EE 0%, #0945BF 100%)",
    padding: "12px 28px 12px 28px",
    cursor: "pointer",
    borderRadius: "6px",
    color: "#fff",
    fontSize: "16px",
  },
  buttonContainer: {
    width: "100%",
    textAlign: "right",
  },
};

const useStyles = makeStyles(styles);
function FileUpload({userInfo}) {
  const [files, setFiles] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertMessageType, setAlertMessageType] = React.useState("success");
  const [uploadError, setUploadError] = useState(null);
  const classes = useStyles();
  const onDrop = (acceptedFiles) => {
    setFiles((prev) => [...prev, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const truncateFileName = (name) => {
    const maxLength = 20;
    if (name.length <= maxLength) return name;
    const extIndex = name.lastIndexOf(".");
    const nameWithoutExt = name.substring(0, extIndex);
    const ext = name.substring(extIndex);
    return `${nameWithoutExt.substring(
      0,
      maxLength - ext.length - 3
    )}...${ext}`;
  };

  const fileItems = files.map((file, index) => (
    <li key={index} className="">
      {truncateFileName(file.name)} - {(file.size / 1024 ** 2).toFixed(2)} Mb
      <button
        className={classes.removeButton}
        onClick={() => handleRemoveFile(index)}
      >
        X
      </button>
    </li>
  ));

  const formData = new FormData();
  var zip = new JSZip();
  var zipFileName = "files.zip";
  var zipPromises = [];
  function handleFile(file) {
    if (file.type === "application/zip") {
      return JSZip.loadAsync(file).then((content) => {
        const internalPromises = [];
        content.forEach((relativePath, zipEntry) => {
          internalPromises.push(
            zipEntry.async("blob").then((blob) => {
              zip.file(relativePath, blob);
            })
          );
        });
        return Promise.all(internalPromises);
      });
    } else {
      zip.file(file.name, file);
      return Promise.resolve();
    }
  }

  const handleUpload = async () => {
    setFileUploading(true);
    setUploadError(null);

    files.forEach((file) => {
      zipPromises.push(handleFile(file));
    });

    try {
      await Promise.all(zipPromises);
      const content = await zip.generateAsync({ type: "blob" });
      formData.append("file", content, zipFileName);
      formData.append("UserId", userInfo?.userId);

      const response = await fetch(
        `${serverDjangoURL}/api/users/uploadFiles`,
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      if (response.ok) {
        setAlertMessage("Upload Successfully");
        setAlertDialog(true);
        if (!result?.payload) {
          setUploadError(result.message);
          setAlertMessageType("danger");
          setAlertMessage(result.message);
        }
      } else {
        setUploadError(result.message || "File upload failed");
        throw new Error(result.message || "File upload failed");
      }
    } catch (error) {
      console.error("Error:", error);
      setAlertMessageType("danger");
      setAlertMessage(error.message);
      setUploadError(error.message);
    } finally {
      setFileUploading(false);
      setFiles([]);
    }
  };

  const handleAlertOK = async () => {
    setAlertDialog(false);
  };

  return (
    <>
      <section className={classes.container}>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p className={classes.uploadText}>Upload or Drag your file</p>
          <img src={fileUpload} alt="..." height={"60px"} width={"60px"} />
        </div>
        {fileItems.length ? (
          <aside className={classes.files}>
            <h4>Files</h4>
            <ul>{fileItems} </ul>
          </aside>
        ) : (
          ""
        )}

        {fileItems.length ? (
          <div className={classes.buttonContainer}>
            <button
              className={classes.uploadButton}
              onClick={handleUpload}
              disabled={fileUploading}
            >
              {fileUploading ? "Uploading..." : "Upload All Files"}
            </button>
          </div>
        ) : (
          ""
        )}
        {uploadError && (
          <div className={classes.errorCard}>
            <p>Error: {uploadError}</p>
          </div>
        )}
        {/* <button onClick={handleUpload}>Upload All Files</button> */}
      </section>
      <AlertDialog
        open={alertDialog}
        type={alertMessageType}
        title={alertMessage}
        ContentText={null}
        cancelable={() => {}}
        action={[{ text: "OK", onPress: handleAlertOK }]}
      />
    </>
  );
}

export default FileUpload;
