import React, { useState, useEffect } from "react";
import axios from "axios";
import CommonAnalysisTable from "./CommonAnalysisTable";
import PageNavigation from "components/CustomComponents/Navbars/PageNavigation";
import TableLoader from "./TableLoader";
import { useSelector } from "react-redux";
import { serverDjangoURL } from "variables/ServerURL";
import { convertSeconds } from "util/commonfunctions";
import AssignMentOverallReport from "./AssignMentOverallReport";

function AssessmentTestReport() {
  const [performanceData, setPerformanceData] = useState([]);
  const [performanceLoading, setPerformanceLoading] = useState(true);
  const [performanceError, setPerformanceError] = useState(null);
  const [timeManagement, setTimeManagement] = useState(true);
  const [timeManagementLoading, setTimeManagementLoading] = useState(true);
  const [timeManagementError, setTimeManagementError] = useState(null);
  const userInfo = useSelector((state) => state.user.userinfo);

  useEffect(() => {
    fetchPerformanceData();
    // fetchOverallPerformanceData();
    fetchTimeManagementData();
  }, []);

  const fetchTimeManagementData = async () => {
    setTimeManagementLoading(true);
    try {
      const response = await axios.post(
        `${serverDjangoURL}/api/search/getTestTimeReport/`,
        {
          userId: userInfo.userId,
        }
      );

      if (response?.data?.data) {
        setTimeManagement(timeManagementModifiedData(response.data.data));
      }
    } catch (error) {
      setTimeManagementError("Failed to fetch performance data");
    } finally {
      setTimeManagementLoading(false);
    }
  };

  const timeManagementModifiedData = (apiData) => {
    return [
      {
        map: {
          testName: "Test Name",
          your_time: "Your Time",
          Average_of_All_Student: "Avg of all students (mm:ss)",
          Average_of_Top_Ten_Percentile: "Avg of top 10 percentile (mm:ss)",
        },
        questions: apiData.map((q) => ({
          testName: q?.test_id
            ? `<a href='/report/${q.test_id}' style='color:#32b8b0'>${q.test_name} </a>`
            : "",
          your_time: q?.user_time ? convertSeconds(q?.user_time) : "NA",
          Average_of_All_Student: convertSeconds(q.attempt_time),
          Average_of_Top_Ten_Percentile: `${convertSeconds(
            q.top_10_average
          )} / ${Math.round(q.actual_duration)} min`,
        })),
      },
    ];
  };

  const fetchPerformanceData = async () => {
    setPerformanceLoading(true);
    try {
      const response = await axios.post(
        `${serverDjangoURL}/api/search/getPerformanceCategoryReport/`,
        {
          userId: userInfo.userId,
        }
      );
      if (response?.data?.data) {
        setPerformanceData(processPerformanceData(response.data.data));
      }
    } catch (error) {
      setPerformanceError("Failed to fetch performance data");
    } finally {
      setPerformanceLoading(false);
    }
  };

  // const processPerformanceData = (apiData) => {
  //   return [
  //     {
  //       map: {
  //         testName: "Test Name",
  //         above_85: ">85%",
  //         between_75_85: "75-85%",
  //         between_63_75: "63-75%",
  //         between_55_63: "55-63%",
  //         between_48_55: "48-55%",
  //         between_40_48: "40-48%",
  //         below_40: "<40%",
  //         not_attempted: "Not Attempted",
  //       },
  //       questions: apiData.map((q) => {
  //         const linkStyle =
  //           "cursor:pointer; text-decoration:underline; font-size:14px;  color:#000000;";
  //         const normalStyle = "color:inherit; font-size:13px;";

  //         const createCell = (value, type) => {
  //           return q.TestId === "Overall"
  //             ? `<div style='${normalStyle}'>${value}</div>`
  //             : `<div class='clickShowModal' style='${linkStyle}' data-id='${q.TestId}' data-count='${value}' data-type='${type}' data-name='${q.TestName}'>${value}</div>`;
  //         };

  //         return {
  //           testName:
  //             q?.TestId !== "Overall"
  //               ? `<a href='/report/${q.TestId}' style='color:#32b8b0;font-size:13px'>${q.TestName}</a>`
  //               : "Overall",
  //           above_85: createCell(q.Percentages.above_85, "above_85"),
  //           between_75_85: createCell(
  //             q.Percentages.between_75_85,
  //             "between_75_85"
  //           ),
  //           between_63_75: createCell(
  //             q.Percentages.between_63_75,
  //             "between_63_75"
  //           ),
  //           between_55_63: createCell(
  //             q.Percentages.between_55_63,
  //             "between_55_63"
  //           ),
  //           between_48_55: createCell(
  //             q.Percentages.between_48_55,
  //             "between_48_55"
  //           ),
  //           between_40_48: createCell(
  //             q.Percentages.between_40_48,
  //             "between_40_48"
  //           ),
  //           below_40: createCell(q.Percentages.below_40, "below_40"),
  //           not_attempted: createCell(
  //             q.Percentages.not_attempted,
  //             "not_attempted"
  //           ),
  //         };
  //       }),
  //     },
  //   ];
  // };

  const processPerformanceData = (apiData) => {
    return [
      {
        map: {
          testName: "Test Name",
          above_85: ">85%",
          between_75_85: "75-85%",
          between_63_75: "63-75%",
          between_55_63: "55-63%",
          between_48_55: "48-55%",
          between_40_48: "40-48%",
          below_40: "<40%",
          not_attempted: "Not Attempted",
        },
        questions: apiData.map((q) => {
          const linkStyle =
            "cursor:pointer; text-decoration:underline; font-size:14px; color:#000000;";
          const normalStyle = "color:inherit; font-size:13px;";

          const createCell = (value, type) => {
            // Check if this test has UserPerformance and if this category matches user's performance
            const isUserPerformanceCategory =
              q.UserPerformance && q.UserPerformance.category === type;
            const backgroundColor = isUserPerformanceCategory
              ? "background-color: #e6ffe6;"
              : "";

            return q.TestId === "Overall"
              ? `<div style='${normalStyle}'>${value}</div>`
              : `<div class='clickShowModal' style='${linkStyle}; ${backgroundColor}' data-id='${q.TestId}' data-count='${value}' data-type='${type}' data-name='${q.TestName}'>${value}</div>`;
          };

          return {
            testName:
              q?.TestId !== "Overall"
                ? `<a href='/report/${q.TestId}' style='color:#32b8b0;font-size:13px'>${q.TestName}</a>`
                : "Overall",
            above_85: createCell(q.Percentages.above_85, "above_85"),
            between_75_85: createCell(
              q.Percentages.between_75_85,
              "between_75_85"
            ),
            between_63_75: createCell(
              q.Percentages.between_63_75,
              "between_63_75"
            ),
            between_55_63: createCell(
              q.Percentages.between_55_63,
              "between_55_63"
            ),
            between_48_55: createCell(
              q.Percentages.between_48_55,
              "between_48_55"
            ),
            between_40_48: createCell(
              q.Percentages.between_40_48,
              "between_40_48"
            ),
            below_40: createCell(q.Percentages.below_40, "below_40"),
            not_attempted: createCell(
              q.Percentages.not_attempted,
              "not_attempted"
            ),
          };
        }),
      },
    ];
  };
  return (
    <>
      <PageNavigation logoText={"Dashboard"} back={"/assessment-dashboard"} />

      <div style={{ marginTop: "45px", marginBottom: "45px" }}>
        {/* Performance Categories Table */}
        {performanceLoading ? (
          <TableLoader
            heading={"Performance Categories"}
            data={"Loading performance data..."}
            loading={performanceLoading}
            error={performanceError}
          />
        ) : performanceError ? (
          <TableLoader
            heading={"Performance Categories"}
            data={performanceError}
            loading={performanceLoading}
            error={performanceError}
          />
        ) : (
          <CommonAnalysisTable
            data={performanceData}
            heading={"Performance Categories"}
            firstTdStyle={{
              width: "20%",
              textAlign: "left",
              whiteSpace: "normal",
            }}
          />
        )}

        {/* Performance Analysis Section */}
        <AssignMentOverallReport />

        <div>
          {timeManagementLoading ? (
            <TableLoader
              heading={"Time Management"}
              data={"Loading Time Management data..."}
              loading={timeManagementLoading}
              error={timeManagementError}
            />
          ) : timeManagementError ? (
            <TableLoader
              heading={"Time Management"}
              data={"Failed to fetch Time Managemenrt  data"}
              loading={timeManagementLoading}
              error={timeManagementError}
            />
          ) : (
            <CommonAnalysisTable
              data={timeManagement}
              heading={"Time Management"}
              style={{ width: "50%", margin: "0" }}
              firstTdStyle={{
                width: "20%",
                textAlign: "left",
                whiteSpace: "normal",
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default AssessmentTestReport;
